<template>
    <div class="thumbnail" @click="goTo(projectId)">
        <div class="thumbnail__img">
            <img :src="imageUrl" alt="Thumbnail Image" />
            </div>
            <div class="thumbnail__footer">
            <div class="thumbnail__footer__left">
                <span>{{projectTitle}} #{{projectId}}</span>
                <em>coming soon</em>
            </div>
            <div class="thumbnail__footer__right">
                <img src="@/assets/icons/logo-twitter-blue.svg" alt="Twitter Logo">
                <span>{{authorWebsite}}</span>  
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    projectTitle: {
      type: String,
      required: true,
    },
    imageUrl: {
        type: String,
        required: true,
    },
    projectId: {
        type: String,
        required: true,
    },
    authorWebsite: {
        type: String,
        required: true
    }
  },
  data() {
      return {}
  },
  methods: {
    goTo(projectId) {
        this.$router.push({name: 'project', params: { projectId }});
        window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &__img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: center;
    flex-wrap: wrap;
    gap:8px;
    &__left {
        display: flex;
        column-gap: 8px;
        align-items: center;
        span {
            color: var(--color-neutral-black);
            margin: 0px;
            line-height: 24px;
        }
        em {
            color: var(--color-neutral-02);
            line-height: 24px;
        }
    }
    &__right {
        display: flex;
        column-gap: 4px;
        align-items: center;
        span {
            color: var(--color-emerald);
            line-height: 16px;
        }
    }
  }
}
</style>
