<template>
  <div class="project__info">
      <slot name="content"/>
  </div>
</template>

<script>
export default {
    data() {
        return {
            isCollapsable: false,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
