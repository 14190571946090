<template>
  <div class="progress__bar" v-if="!isLoading">
    <div class="full__bar">
      <div :class="`bar__minted`" :style="{ width: percentageMinted + '%' }"></div>
      <div class="bar__dead__stock" :style="{ width: percentageDeadStock + '%' }"></div>
      <div class="bar__free__minted" :style="{ width: percentageFreeMinted + '%' }"></div>
    </div>
    <div class="labels">
        <div class="label">
            <img src="@/assets/icons/bar-indicator-black.svg" alt="">    
            <span><strong>{{publicMintedCount}}</strong>Total minted</span>
        </div>
        <div class="label">
            <img src="@/assets/icons/bar-indicator-yellow.svg" alt="">    
            <span><strong>{{deadStock}}</strong>Deadstock</span>
        </div>
        <div class="label">
            <img src="@/assets/icons/bar-indicator-blue.svg" alt="">    
            <span>
                <strong>{{currentFreeMintsRedeemed}} of {{ totalFreeMints }}</strong>
                Free mints redeemed
            </span>
        </div>
    </div>
  </div>
</template>

<script>
import { stringIdToNumber, initContracts } from "@/utils";
import { refContract } from '@stakeordie/griptape.js';

let tokensLimit = stringIdToNumber(process.env.VUE_APP_STUDIES_ID_LIMIT) + 1;

export default {
  name: "progress-bar",
  props: {
    contractAddress: { type: String,  required: true },
  },
  data() {
    return {
      minted: null,
      deadStock: null,
      totalFreeMints: null,
      
      percentageMinted:null,
      percentageDeadStock: null,
      percentageFreeMinted: null,
      
      totalMints: 1024,
      publicMintedCount: tokensLimit,
      currentFreeMintsRedeemed: 0,

      isLoading: false,
    };
  },
  async mounted() {
    await initContracts();
    await this.getTotalFreeMintedTokens();
    this.getProgressBarPercentageValues();
  },
  methods: {
    async getTotalFreeMintedTokens() {
        this.isLoading = true;
        const snip721ContractAddress = refContract(this.contractAddress);
        try {
            let tokens = await snip721ContractAddress.getAllTokens();
            let content = tokens.token_list.tokens;

            if(content.length > 0 ) {
                this.deadStock = this.totalMints - (this.publicMintedCount * 2);
                this.totalFreeMints = this.publicMintedCount;

                let freeTokens = content.map(token => stringIdToNumber(token))
                .filter(token => token >= (tokensLimit));
                this.currentFreeMintsRedeemed = freeTokens.length - this.deadStock;
            }
        }catch(error) {
            console.error('Error Progress Bar', error)
        } finally {
            this.isLoading = false;
        }    
    },
    getProgressBarPercentageValues() {
        this.percentageMinted = (this.publicMintedCount / this.totalMints) * 100;
        this.percentageDeadStock = (this.deadStock / this.totalMints) * 100;

        let freeMintsCounterPercentage = (this.currentFreeMintsRedeemed / this.totalFreeMints);
        this.percentageFreeMinted = (100 - (this.percentageMinted + this.percentageDeadStock)) * freeMintsCounterPercentage;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress__bar {
  width: 100%;
  display: grid;
  grid-template-rows: (3, 1fr);
  row-gap: 8px;
  margin-bottom: 16px;
  .labels {
    display: flex;
    column-gap: 8px;
    align-items: center;
    .label {
        display: flex;
        column-gap: 6px;
        align-items: center;
        span {
            line-height: 24px;
        }
        strong {
            font-weight: 700;
            line-height: 24px;
            margin-right: 4px; 
        }
    }
  }
  .full__bar {
    height: 12px;
    display: flex;
    background: var(--color-neutral-03);
    .bar {
      height: 12px;
      width: 15px;
      &__minted {
        background: var(--color-neutral-black);
      }
      &__dead__stock {
        background: var(--color-yellow);
      }
      &__free__minted {
        background: var(--color-emerald);
      }
    }
  }
}
</style>
