<template>
  <div class="last__minted__tokens">
    <div class="last__minted__tokens__title">
        <h1>Recently Minted Tokens</h1>
    </div>

    <div 
        v-if="lastsMinted.length == 0" class="last__minted__tokens__unavailable"
    >
        <span>There are no minted tokens available.</span>
    </div>
    <div v-else class="last__minted__tokens__grid" >
        <thumbnail-last-minted 
            v-for="token in lastsMinted" :key="token.id"
            :title="projectTitle"
            :art_number="token.id"
            :image_url="token.previewImageUrl"
            :detail_id="token.id"
            :project_id="token.projectId"
        >
        </thumbnail-last-minted >
    </div>
  </div>
</template>

<script>
import ThumbnailLastMinted from '@/components/ThumbnailLastMinted.vue';
import api from '@/service/api.js';
export default {
    components: { ThumbnailLastMinted },
    name: "last-minted-tokens",
    props: {
        projectId: {
            type: String, 
            required: true,
        },
        totalMints: {
            type: Number,
            required: true,
        },
        projectTitle: {
          type: String,
          required: true
        }
    },
    data() {
        return {
            lastsMinted: [],
        }
    },
    async mounted () {
        await this.getLastArtsGeneratedByProject();
    },
    methods: {
        async getLastArtsGeneratedByProject() {
            try {
                const res = await api.projects.arts.getGenerated(
                    this.projectId,
                    this.totalMints
                )
                this.lastsMinted= res.content
                .filter(art => art.status === "GENERATED")
                .slice(0, 4).reverse();
            } catch(error) {
                console.error(error);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.last__minted__tokens {
    h1, p { margin: 0px }
    display: grid;
    grid-template-rows: repeat(2, auto);
    row-gap: 16px;
    &__title {
        padding: 16px;
        h1 {
            font-style: italic;
            line-height: 46px;
        }
    }
    &__unavailable {
        padding: 16px;
        line-height: 24px;
    }
    &__grid {
        padding-top: 16px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 32px;
        padding: 16px;
        @include respond-to("small and down") {
            padding-top: 0px;
            display: grid;
            grid-template-columns: unset;
            grid-row-gap: 16px;
            margin-bottom: 0px;
        }
    }
}
</style>
