<template>
  <div class="progress__bar" v-if="mintedCount > 0">
    <div class="full__bar">
      <div class="bar__black" :style="{ width: percentageMinted + '%' }"></div>
      <div class="bar__yellow" :style="{ width: percentageDeadStock + '%' }" v-if="status == 'mint_ended'"></div>
    </div>
    <div class="labels">
        <div class="label">
            <img src="@/assets/icons/bar-indicator-black.svg" alt="">    
            <span><strong>{{mintedCount}}</strong>minted</span>
        </div>
        <div class="label" v-if="status == 'public_mint'">
            <img src="@/assets/icons/bar-indicator-gray.svg" alt="">    
            <span><strong>{{tokensToLeft}}</strong>left</span>
        </div>
        <div class="label" v-if="status == 'mint_ended'">
            <img src="@/assets/icons/bar-indicator-yellow.svg" alt="">    
            <span><strong>{{deadStock}}</strong>deadstock</span>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "progress-bar",
  props: {
    status: { type: String, required: true },
    totalMints: { type: Number, required: true },
    mintedCount: {type: Number, required: true },
  },
  data() {
    return {
      left: null,
      deadStock: null,
      percentageMinted:null,
      percentageDeadStock: null,
    };
  },
  mounted() {
    this.calculateDeadStock();
    this.getProgressBarPercentageValues();
  },
  computed: {
    tokensToLeft() {
        return this.totalMints - this.mintedCount;
    },
  },
  methods: {
    getProgressBarPercentageValues() {
        this.percentageMinted = (this.mintedCount / this.totalMints) * 100;
        this.percentageDeadStock = (this.deadStock / this.totalMints) * 100;
    },
    calculateDeadStock() {
        this.deadStock = this.totalMints - this.mintedCount;
    }
  },
};
</script>

<style lang="scss" scoped>
.progress__bar {
  width: 100%;
  display: grid;
  grid-template-rows: (2, 1fr);
  row-gap: 8px;
  margin-bottom: 16px;
  .labels {
    display: flex;
    column-gap: 8px;
    align-items: center;
    .label {
        display: flex;
        column-gap: 6px;
        align-items: center;
        span {
            line-height: 24px;
        }
        strong {
            font-weight: 700;
            line-height: 24px;
            margin-right: 4px; 
        }
    }
  }
  .full__bar {
    height: 12px;
    display: flex;
    background: var(--color-neutral-03);
    .bar {
      height: 12px;
      width: 15px;
      &__black {
        background: var(--color-neutral-black);
      }
      &__yellow {
        background: var(--color-yellow);
      }
    }
  }
}
</style>
