<template>
  <div class="upcoming__tokens" v-if="!upcomingProjects.length === 0">
    <div 
        class="upcoming__tokens__title"
    >
        <h3>Upcoming Projects</h3>
    </div>

    <div class="upcoming__tokens__grid" >
        <thumbnail-upcoming-project
            v-for="project in upcomingProjects" :key="project.id"
            :project-title="project.title"
            :image-url="project.previewImageUrl"
            :project-id="project.id"
            :author-website="project.authorWebsite"
        >
        </thumbnail-upcoming-project>
    </div>
  </div>
</template>

<script>
import ThumbnailUpcomingProject from '@/components/Projects/ThumbnailUpcomingProject.vue';
import api from '@/service/api.js';
export default {
    components: { ThumbnailUpcomingProject },
    name: "last-minted-tokens",
    data() {
        return {
            upcomingProjects : [],
        }
    },
    async mounted () {
        await this.getUpcomingProjects();
    },
    methods: {
        async getUpcomingProjects() {
            try {
                const res = await api.projects.getAll();
                this.upcomingProjects = res
                .filter(art => art.status == "upcoming")
            } catch(error) {
                console.error(error);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.upcoming__tokens {
    h1, p { margin: 0px }
    display: grid;
    grid-template-rows: repeat(2, auto);
    row-gap: 16px;
    &__title {
        padding: 16px;
        h3 {
            line-height: 33px;
            margin: 0px;
        }
    }
    &__unavailable {
        padding: 16px;
        line-height: 24px;
    }
    &__grid {
        padding-top: 16px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 32px;
        padding: 16px;
        @include respond-to("small and down") {
            padding-top: 0px;
            display: grid;
            grid-template-columns: unset;
            grid-row-gap: 16px;
            margin-bottom: 0px;
        }
    }
}
</style>
